import React from "react"
import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
import "./Article.scss"
import { Link } from "gatsby"
import Badge from "../Badge/Badge"
import moment from "moment"

const ArticleCard = props => {
  const imgLink =
    props.item.imgLink ||
    `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/logo/neurond-final-white.svg`

  return (
    <article className="article-card">
      {props.index % 2 === 1 ? (
        <>
          <div className="col-md-6 article-card__block picture-block block-custom">
            {
              props.item.slug ? (<Link to={`/blog/${props.item.slug}`}>
                <img
                  className={`article-card__block__img ${
                    !props.item.imgLink && "default-thumbnail"
                  }`}
                  src={imgLink}
                  alt={
                    props.item.h3Title
                      ? props.item.h3Title
                      : props.item.h2Title
                      ? props.item.h2Title
                      : "Neurond AI"
                  }
                />
              </Link>) : (<img
                  className={`article-card__block__img ${
                    !props.item.imgLink && "default-thumbnail"
                  }`}
                  src={imgLink}
                  alt={
                    props.item.h3Title
                      ? props.item.h3Title
                      : props.item.h2Title
                      ? props.item.h2Title
                      : "Neurond AI"
                  }
                />)
            }
          </div>
          <div className="col-md-6 article-card__block content-block block-custom">
            {/* {props.item.headLine && (
              <div className="article-card__block__headline"></div>
            )} */}
            {props.item.isBadge && (
                <div className="row align-items-center">
                <Badge badgeData={props.item.categoryData} wrapLine />
                {props.item.date && (
                  <div className="blog-related__date">{moment(new Date(props.item.date)).format("DD/MM/YYYY")}</div>
                )}
              </div>
            )}
            {props.item.h2Title && (
              <HandleOrphan>
                {
                  props.item.slug ? (
                    <h2 className="h2-text fix-orphan article-card__block__title add-pd">
                      <Link to={`/blog/${props.item.slug}`}>{props.item.h2Title}</Link>
                    </h2>) : (
                    <h2 className="h2-text fix-orphan article-card__block__title add-pd">
                      {props.item.h2Title}
                    </h2>)
                }
              </HandleOrphan>
            )}
            {props.item.h3Title && (
              <HandleOrphan>
                {
                  props.item.slug ? (
                    <h3 className="h3-text fix-orphan article-card__block__title">
                      <Link to={`/blog/${props.item.slug}`}>{props.item.h3Title}</Link>
                    </h3>) : (
                    <h3 className="h3-text fix-orphan article-card__block__title">
                      {props.item.h3Title}
                    </h3>)
                }
              </HandleOrphan>
            )}
            {props.item.subTitle && (
              <HandleOrphan>
                {
                  props.item.slug ? (
                    <p className="sub-content fix-orphan article-card__block__sub-title">
                      <Link to={`/blog/${props.item.slug}`}>{props.item.subTitle}</Link>
                    </p>) : (
                    <p className="sub-content fix-orphan article-card__block__sub-title">
                      {props.item.subTitle}
                    </p>)
                }
              </HandleOrphan>
            )}
            {props.item.author && (
              <div className="row align-items-center m-minus-15">
                <div className="col-2">
                  <img className="img-fluid article-card__block__author-img" src={props.item.author.avatar.url} alt={props.item.author.name}/>
                </div>
                <div className="col-10">
                  <p className="article-card__block__author-name">{props.item.author.name}</p>
                  <p className="article-card__block__author-desc">{props.item.author.occupation.occupation}</p>
                </div>
              </div>
            )}
            {props.item.description &&
              props.item.description.map((i, index) => {
                return (
                  <HandleOrphan key={index}>
                    <p className="h4-text fix-orphan article-card__block__description">
                      {i}
                    </p>
                  </HandleOrphan>
                )
              })}
            {props.item.isReadMore && (
              <Link className="article-card__block__read-more" to={`/blog/${props.item.slug}`}>
                  Read More &nbsp;{">"}
              </Link>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="col-md-6 article-card__block content-block block-custom">
            {/* {props.item.headLine && (
              <div className="article-card__block__headline"></div>
            )} */}
            {props.item.isBadge && (
                <div className="row align-items-center">
                <Badge badgeData={props.item.categoryData} wrapLine />
                {props.item.date && (
                  <div className="blog-related__date">{moment(new Date(props.item.date)).format("DD/MM/YYYY")}</div>
                )}
              </div>
            )}
            {props.item.h2Title && (
              <HandleOrphan>
                {
                  props.item.slug ? (
                    <h2 className="h2-text fix-orphan article-card__block__title add-pd">
                      <Link to={`/blog/${props.item.slug}`}>{props.item.h2Title}</Link>
                    </h2>) : (
                    <h2 className="h2-text fix-orphan article-card__block__title add-pd">
                      {props.item.h2Title}
                    </h2>)
                }
              </HandleOrphan>
            )}
            {props.item.h3Title && (
              <HandleOrphan>
                {
                  props.item.slug ? (
                    <h3 className="h3-text fix-orphan article-card__block__title">
                      <Link to={`/blog/${props.item.slug}`}>{props.item.h3Title}</Link>
                    </h3>) : (
                    <h3 className="h3-text fix-orphan article-card__block__title">
                      {props.item.h3Title}
                    </h3>)
                }
              </HandleOrphan>
            )}
            {props.item.subTitle && (
              <HandleOrphan>
                {
                  props.item.slug ? (
                    <p className="sub-content fix-orphan article-card__block__sub-title">
                      <Link to={`/blog/${props.item.slug}`}>{props.item.subTitle}</Link>
                    </p>) : (
                    <p className="sub-content fix-orphan article-card__block__sub-title">
                      {props.item.subTitle}
                    </p>)
                }
              </HandleOrphan>
            )}
            {props.item.description &&
              props.item.description.map((i, index) => {
                return (
                  <HandleOrphan key={index}>
                    <p className="h4-text fix-orphan article-card__block__description">
                      {i}
                    </p>
                  </HandleOrphan>
                )
              })}
            {props.item.isReadMore && (
              <Link to={`/blog/${props.item.slug}`}>
                <p className="article-card__block__read-more">
                  Read More &nbsp;{">"}
                </p>
              </Link>
            )}
          </div>
          <div className="col-md-6 article-card__block picture-block block-custom">
            {
              props.item.slug ? (<Link to={`/blog/${props.item.slug}`}>
                <img
                  className={`article-card__block__img ${
                    !props.item.imgLink && "default-thumbnail"
                  }`}
                  src={imgLink}
                  alt={
                    props.item.h3Title
                      ? props.item.h3Title
                      : props.item.h2Title
                      ? props.item.h2Title
                      : "Neurond AI"
                  }
                />
              </Link>) : (<img
                  className={`article-card__block__img ${
                    !props.item.imgLink && "default-thumbnail"
                  }`}
                  src={imgLink}
                  alt={
                    props.item.h3Title
                      ? props.item.h3Title
                      : props.item.h2Title
                      ? props.item.h2Title
                      : "Neurond AI"
                  }
                />)
            }
          </div>
        </>
      )}
    </article>
  )
}
export default ArticleCard
